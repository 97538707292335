const htmlContent = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms of Service (EULA)</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 40px;
            padding: 20px;
            line-height: 1.6;
        }
        h1, h2 {
            color: #333;
        }
        ul {
            margin-left: 20px;
        }
        .section {
            margin-bottom: 20px;
        }
    </style>
</head>
<body>
    <h1>Terms of Service (EULA)</h1>
    <p><strong>Effective Date:</strong> 02/03/2025</p>
    <p>Welcome to <strong>TimeSavor</strong> ("the App"). By using our App, you agree to these Terms of Service ("Terms"). If you do not agree, please do not use the App.</p>

    <div class="section">
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using the App, you confirm that you have read, understood, and agreed to be bound by these Terms, including our Privacy Policy. If you do not agree, you must discontinue use immediately.</p>
    </div>

    <div class="section">
        <h2>2. User Conduct & Content Policy</h2>
        <p>Users are expected to behave responsibly and respectfully. You agree NOT to:</p>
        <ul>
            <li>Post, share, or distribute content that includes hate speech, harassment, discrimination, threats, violence, sexually explicit material, or illegal activities.</li>
            <li>Use the App for fraudulent or malicious purposes, including impersonation, phishing, or spam.</li>
            <li>Engage in abusive behavior, cyberbullying, or harassment of other users.</li>
            <li>Violate any applicable local, state, or international laws.</li>
        </ul>
        <p>We reserve the right to remove any content that violates these Terms and to suspend or ban users who repeatedly engage in objectionable conduct.</p>
    </div>

    <div class="section">
        <h2>3. Content Moderation & Reporting</h2>
        <p>We have a strict moderation system in place. Users can report content they find objectionable using the in-app Report Content button. Our team will review reports and take action within 24 hours, including:</p>
        <ul>
            <li>Removing the reported content.</li>
            <li>Taking appropriate action against users who violate our policies, including suspension or permanent bans.</li>
        </ul>
        <p>If you believe your content was removed by mistake, you may contact our support team for a review.</p>
    </div>

    <div class="section">
        <h2>4. Blocking & Safety Features</h2>
        <p>To ensure a safe user experience, we provide tools to:</p>
        <ul>
            <li><strong>Block users:</strong> You can block any user from interacting with you.</li>
            <li><strong>Flag content:</strong> If you encounter inappropriate content, you can flag it for review.</li>
        </ul>
    </div>

    <div class="section">
        <h2>5. Termination & Enforcement</h2>
        <p>We reserve the right to suspend or terminate your account at any time if you violate these Terms. Repeat offenders will face permanent bans.</p>
    </div>

    <div class="section">
        <h2>6. Changes to These Terms</h2>
        <p>We may update these Terms from time to time. Users will be notified of any changes via in-app notifications or email. Continued use of the App after changes means you accept the updated Terms.</p>
    </div>

    <div class="section">
        <h2>7. Contact Information</h2>
        <p>If you have any questions or concerns about these Terms, please contact us at:</p>
        <p><strong>contact@timesavorapp.com</strong></p>
    </div>
    <p>By using the App, you acknowledge that you have read, understood, and agree to abide by these Terms of Service.</p>
</body>
</html>

`;

export default htmlContent;